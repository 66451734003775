import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query BrandPageBySlug(
        $locale: String!
        $slug: String!
        $limit: Int!
        $textBlockId: String!
        $preview: Boolean
    ) {
        siteinfoCollection(locale: $locale, limit: 1) {
            items {
                ...brandPageSiteinfo
            }
        }
        pageCollection(
            locale: $locale
            where: { slug: $slug }
            limit: $limit
            preview: $preview
        ) {
            items {
                ...brandPage
            }
        }
        textBlockCollection(
            locale: $locale
            where: { sys: { id: $textBlockId } }
            limit: 1
        ) {
            items {
                ...textBlock
            }
        }
    }
`);
