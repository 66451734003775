/* eslint-disable no-param-reassign */

import type {
    AuthRegisterErrorDto,
    CategoryPageLinkFragment,
    ExceptionalOpeningHoursDay,
    HeaderStoreFragment,
    KeyValue,
    Maybe,
    OpeningHoursDay,
    UserProfileUpdateErrorDto,
} from '@server/gql/graphql';
import type { Dictionary } from 'types/common';

export const getCurrencySymbol = (currency: string) => {
    switch (currency.toUpperCase()) {
        case 'EUR':
            return ['€', 'start'];
        case 'USD':
            return ['$', 'start'];
        case 'GBP':
            return ['£', 'start'];
        case 'SEK':
            return ['kr', 'end'];
        default:
            return ['€', 'start'];
    }
};

export const sortNullValuesLast = (array: any[], key: string) =>
    array.sort((a: any, b: any) => {
        if (a[key] === null) return 1;
        if (b[key] === null) return -1;
        return 0;
    });

export const camelCase = (str: string) =>
    str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
            index === 0 ? word.toLowerCase() : word.toUpperCase(),
        )
        .replace(/\s+/g, '');

export const createDictionary = (list: KeyValue[] = []): Dictionary => {
    const excludeFromLogging = [
        'toString',
        '0',
        'then',
        'vadd',
        '@@iterator',
        '$$typeof',
        'toJSON',
    ];
    const proxy = new Proxy<Dictionary>(
        {},
        {
            get(target, prop) {
                if (
                    process.env.NODE_ENV === 'development' &&
                    typeof prop === 'string' &&
                    !(prop in target) &&
                    !excludeFromLogging.includes(prop)
                ) {
                    // eslint-disable-next-line no-console
                    console.log(`Missing dictionary key: ${prop}`);
                    return `!!${prop}`;
                }
                return target[String(prop)];
            },
        },
    );

    list?.forEach((kv: KeyValue) => {
        const key = kv?.key as string;
        const value = kv?.value as string;
        proxy[key] = value;
    });

    return proxy;
};

export const getCredentialsFromCookie = (
    cookie = ':',
): { cartId: number; token: string } => ({
    cartId: Number(cookie.split(':').shift()),
    token: String(cookie.split(':').pop()),
});

export const getNavigationLinkAncestors = (
    target: string,
    children: (CategoryPageLinkFragment | null)[],
    ancestors: CategoryPageLinkFragment[] = [],
): CategoryPageLinkFragment[] | undefined => {
    if (!children) return undefined;
    for (let i = 0; i < children.length; i += 1) {
        const item = children[i];
        if (item && item.internalLink?.__typename === 'Page') {
            if (item.internalLink.slug === target) {
                return ancestors.concat(item);
            }
            if (
                item.sublinksCollection?.items &&
                item.sublinksCollection.items.length > 0
            ) {
                const ancestor = getNavigationLinkAncestors(
                    target,
                    item.sublinksCollection.items as CategoryPageLinkFragment[],
                    ancestors.concat(item),
                );
                if (ancestor) {
                    return ancestor;
                }
            }
        }
    }

    return undefined;
};

export function getOpeningHoursDisplayData(initialData: OpeningHoursDay[]) {
    const days: { text: string; time: string }[] = [];
    const data = initialData.map((item) => {
        if (item.closed) {
            item.openingTime = undefined;
            item.closingTime = undefined;
        }
        return item;
    });

    if (!data.length) return undefined;

    let text = '';
    let i = 0;

    while (i < data.length) {
        const day = data[i];
        const nextDay = data[i + 1];
        const dayClosed = Boolean(day?.closed);
        const sameTime =
            day.openingTime === nextDay?.openingTime &&
            day.closingTime === nextDay?.closingTime;

        if (!sameTime && !dayClosed) {
            days.push({
                text: day.dayOfTheWeek || '',
                time: `${day.openingTime} - ${day.closingTime}`,
            });
            i += 1;
        } else {
            const localeIndex = i;
            text = dayClosed ? '' : (day.dayOfTheWeek as string).slice(0, 3);
            const last = data.findIndex(
                (d, j) =>
                    j > localeIndex &&
                    (day.openingTime !== d.openingTime ||
                        day.closingTime !== d.closingTime),
            );
            const lastSameTimeIndex = last === -1 ? data.length - 1 : last - 1;
            text += dayClosed
                ? ''
                : `-${data[lastSameTimeIndex].dayOfTheWeek?.slice(0, 3)}`;
            if (!dayClosed) {
                days.push({
                    text: `${text}`,
                    time: `${day.openingTime} - ${day.closingTime}`,
                });
            }
            i = lastSameTimeIndex + 1;
        }
    }
    return days;
}

/**
 * @param date - non inclusive date to start search from
 */
function findNextOpenDate({
    date,
    contentfulLocale,
    openingHours,
    exceptionalOpeningHours,
}: {
    date: Date;
    contentfulLocale?: string;
    openingHours: OpeningHoursDay[];
    exceptionalOpeningHours?: ExceptionalOpeningHoursDay[];
}) {
    for (let i = 0; i < 5; i += 1) {
        date.setDate(date.getDate() + 1);

        const dotw = date.toLocaleDateString(contentfulLocale, {
            weekday: 'long',
        });
        const regularDay = openingHours.find(
            (d: any) => d.dayOfTheWeek.toLowerCase() === dotw,
        );
        if (!regularDay) continue;

        const exceptionalDay = findExceptionalOpeningHourMatchingDate(
            date,
            exceptionalOpeningHours,
        );
        if (exceptionalDay) {
            if (exceptionalDay.closed) {
                continue;
            }
            const exceptionalDate = new Date(exceptionalDay.date);
            const dayIndex = getDayIndexMonToSun(exceptionalDate);
            return {
                day: {
                    openingTime: exceptionalDay.openingTime ?? undefined,
                    dayOfTheWeek: dayIndex.toString(),
                },
            };
        }
        if (!regularDay.closed)
            return {
                day: regularDay,
                isNextDay: i === 0,
            };
    }
    return { day: undefined };
}

function getDayIndexMonToSun(date: Date): number {
    const dayOfWeekSunToSat = date.getDay();
    return dayOfWeekSunToSat === 0 ? 6 : dayOfWeekSunToSat - 1;
}

export function getStoreOpeningHoursToday({
    store,
    contentfulLocale,
}: {
    store?: HeaderStoreFragment;
    contentfulLocale?: string;
}):
    | {
          dateRef: Date;
          today?: {
              openingTime?: Maybe<string>;
              closingTime?: Maybe<string>;
          };
          openingHours: OpeningHoursDay[];
          isClosed: boolean;
      }
    | undefined {
    if (!store || !contentfulLocale) {
        return undefined;
    }

    const todaysDate = new Date();
    const regularOpeningHours = store.openingHoursCollection
        ?.items as OpeningHoursDay[];
    const exceptionalOpeningHours = store.exceptionalOpeningHoursCollection
        ?.items as ExceptionalOpeningHoursDay[];

    if (!contentfulLocale || !regularOpeningHours?.length) {
        return undefined;
    }

    const dotw = todaysDate?.toLocaleDateString(contentfulLocale, {
        weekday: 'long',
    });
    const regularHoursToday = regularOpeningHours.find(
        (hour) => hour?.dayOfTheWeek?.toLowerCase() === dotw?.toLowerCase(),
    );
    const exceptionalHoursToday = findExceptionalOpeningHourMatchingDate(
        todaysDate,
        exceptionalOpeningHours,
    );

    const isClosed =
        isClosedToday(regularHoursToday) ||
        isClosedToday(exceptionalHoursToday);

    return {
        dateRef: todaysDate,
        today: exceptionalHoursToday ?? regularHoursToday,
        openingHours: regularOpeningHours,
        isClosed,
    };
}

function findExceptionalOpeningHourMatchingDate(
    date: Date,
    openingHours?: ExceptionalOpeningHoursDay[],
): ExceptionalOpeningHoursDay | undefined {
    if (!openingHours) {
        return undefined;
    }
    for (const openingHour of openingHours) {
        if (!openingHour || !openingHour.date) {
            continue;
        }
        const exceptionalDate = new Date(openingHour.date);
        if (
            exceptionalDate.toLocaleDateString() === date.toLocaleDateString()
        ) {
            return openingHour;
        }
    }
    return undefined;
}

function isClosedToday(
    openingHours?: {
        closed?: boolean | null;
        closingTime?: string | null;
    } | null,
) {
    const todaysDate = new Date();
    const time = todaysDate.getHours();
    return (
        openingHours?.closed ||
        time > parseInt(openingHours?.closingTime ?? '', 10)
    );
}

export function getOpeningHoursText({
    store,
    contentfulLocale,
    openTodayAt,
    opensTomorrowAt,
    opensAtDayOfWeekAt,
}: {
    store?: HeaderStoreFragment;
    contentfulLocale?: string;
    openTodayAt?: string;
    opensTomorrowAt?: string;
    opensAtDayOfWeekAt?: string;
}) {
    try {
        if (!store || !contentfulLocale) {
            return undefined;
        }
        const storeOpeningHoursToday = getStoreOpeningHoursToday({
            store,
            contentfulLocale,
        });
        const exceptionalOpeningHours = store.exceptionalOpeningHoursCollection
            ?.items as ExceptionalOpeningHoursDay[];

        if (storeOpeningHoursToday?.isClosed) {
            const { day: nextOpenDate, isNextDay: nextDay } = findNextOpenDate({
                date: storeOpeningHoursToday.dateRef,
                contentfulLocale,
                openingHours: storeOpeningHoursToday.openingHours,
                exceptionalOpeningHours,
            });

            if (!nextOpenDate) return undefined;

            if (nextDay) {
                return `${opensTomorrowAt} ${nextOpenDate.openingTime}`;
            }

            return `${opensAtDayOfWeekAt?.replace(
                '%DAYOFTHEWEEK%',
                nextOpenDate.dayOfTheWeek ?? '',
            )} ${nextOpenDate.openingTime}`;
        }

        if (storeOpeningHoursToday?.today) {
            return `${openTodayAt} ${storeOpeningHoursToday.today?.openingTime}-${storeOpeningHoursToday.today?.closingTime}`;
        }

        return undefined;
    } catch (error) {
        return undefined;
    }
}

function getDaysBetweenDates({
    dateNow,
    inputDate,
}: {
    dateNow: Date;
    inputDate: Date;
}) {
    const oneDayMs = 1000 * 60 * 60 * 24;
    const dateNowMs = dateNow.getTime();
    const inputDateMs = inputDate.getTime();
    const differenceMs = inputDateMs - dateNowMs;
    return Math.ceil(differenceMs / oneDayMs);
}

export function getIrregularOpeningHoursDisplayData(
    data: ExceptionalOpeningHoursDay[],
    dictionary: Dictionary,
) {
    const days: { text: string; time: string }[] = [];
    const dateNow = new Date();

    for (let i = 0; i < data?.length; i += 1) {
        const day = data[i];
        const inputDate = new Date(day?.date);
        const differenceInDays = getDaysBetweenDates({ dateNow, inputDate });
        if (differenceInDays > -1 && differenceInDays < 8) {
            days.push({
                text: day.title || '',
                time: day.closed
                    ? `${dictionary?.closed}`
                    : `${day.openingTime} - ${day.closingTime}`,
            });
        }
    }
    return days.length > 0 ? days : null;
}

export function getPhoneNumberForLink(phoneNr: Maybe<string> | undefined) {
    if (!phoneNr) return undefined;
    return phoneNr.startsWith('0')
        ? phoneNr.replaceAll(' ', '').replace('-', '').slice(1)
        : phoneNr.replaceAll(' ', '').replace('-', '');
}

/**
 * Transform form name to lowercase with underscores instead of spaces
 * @param name string
 * @returns name string
 */
export function transformStringToSnakeCase(name: string) {
    return name?.toLowerCase().replace(/\s/g, '_');
}

type DeliveryStatusText = {
    status: string;
    dictionary: Dictionary;
};

export function getDeliveryStatusText({
    status,
    dictionary,
}: DeliveryStatusText) {
    if (!dictionary) return undefined;

    switch (status) {
        case 'Allocation':
            return dictionary.allocation;
        case 'Created':
            return dictionary.created;
        case 'ReadyForReservation':
            return dictionary.readyForReservation;
        case 'Delivered':
            return dictionary.delivered;
        case 'Shipped':
            return dictionary.shipped;
        case 'Received':
            return dictionary.received;
        case 'Pending':
            return dictionary.pending;
        default:
            return undefined;
    }
}

export const getActiveLinkStyle = (slug: string, pathname: string) =>
    pathname.endsWith(slug) ? 'underline' : '';

/**
 * This regexp will match a string that starts and ends with one or more digits.
 */
const SLUG_PAGE_NUMBER_REGEX = /^\d+$/;

export const getSlugWithoutPageNumber = (slug?: string[]): string[] =>
    slug?.length
        ? slug.filter((s: string) => s.search(SLUG_PAGE_NUMBER_REGEX) === -1)
        : [];

export const getPageNumberFromSlug = (
    slug: string[] = [],
): number | undefined => {
    const match = slug.find(
        (s: string) => s.search(SLUG_PAGE_NUMBER_REGEX) !== -1,
    );
    if (match !== undefined) return Number(match);
    return undefined;
};

export function getFormSourceBySearchParams({
    source = 'email-registration-form',
    params,
}: {
    source?: string;
    params: URLSearchParams;
}) {
    const utmSource = params.get('utm_source');

    if (utmSource) {
        return `${source}:utm_source=${utmSource}`;
    }

    return source;
}

export const getCapitalizedString = (word: string | undefined) =>
    word ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : '';

export const createFieldError = <T extends object>(
    formError: T,
    validationErrors: (AuthRegisterErrorDto | UserProfileUpdateErrorDto)[],
): T => {
    const formKeys = Object.keys(formError);

    // set all values of formError to empty strings
    const obj = Object.assign({}, ...formKeys.map((k) => ({ [k]: '' })));

    validationErrors.forEach((err) => {
        const errorKey = formKeys.find(
            (key) =>
                !!err?.validationError
                    ?.toUpperCase()
                    .includes(key.toUpperCase()),
        );
        if (errorKey) {
            obj[errorKey] = `error${err.validationError}`;
        }
        if (
            err.validationError === 'MissingFieldValue' &&
            err.validationErrorDetails
        ) {
            obj[err.validationErrorDetails] = 'errorMissingFieldValue';
        }
        if (err.validationError === 'VoyadoPhoneConflict') {
            obj.phoneNumber = 'errorVoyadoPhoneConflict';
        }
        if (
            err.validationError === 'VoyadoValidationError' &&
            err.validationErrorDetails
        ) {
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            const [_, fieldAndErrorMsg] = err.validationErrorDetails
                .split(':')
                .map((s) => s.trim());
            const [field, errorMsg] = fieldAndErrorMsg
                .split('=')
                .map((s) => s.trim());

            // An edge case to show error message on social security message, which is only used on swedish site
            if (field === 'socialSecurityNumber') obj[field] = errorMsg;
        }
    });

    return obj;
};

/**
 * Formats the facebook given date (DD/MM/YYY) to common date string (YYYY-MM-DD)
 * @param dataString string
 */
export const formatFBdateToCommonDateString = (dataString = '') =>
    dataString.split('/').reverse().join('-');

export const truncate = (text: string, length: number, applyDots = true) => {
    const exceedsLength = text.length > length;
    return `${text.slice(0, length)}${exceedsLength && applyDots ? '...' : ''}`;
};

export const validateSocialSecurityNumber = (ssn: string) => {
    const hasNonNumberCharacter = ssn
        .split('')
        .some((character) => Number.isNaN(parseInt(character, 10)));

    if (hasNonNumberCharacter)
        throw new Error('errorSocialSecurityNumberNotANumber');

    if (ssn.length > 0 && ssn.length < 10)
        throw new Error('errorSocialSecurityNumberLength');
    if (ssn.length > 12) throw new Error('errorSocialSecurityNumberLength');

    return true;
};
